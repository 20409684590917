<template>
  <section class="main-wrapper">
    <div class="h-100 row">
      <div class="col-md-4 h-100 ad">
        <div class="mx-auto h-100 flex-column d-flex justify-content-between w-75">
          <div class="pt-3">
            <img src="@/assets/images/exxtra/exxtra-logo.png" width="70px" alt="exxtra-logo" />
          </div>

          <div class="">
            <ol class="position-relative border-left list-unstyled">
              <li class="mb-5 ml-3 cursor-pointer">
                <span class="position-absolute d-flex align-items-center text-primary justify-content-center rounded-full stepper-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                    <path d="M3.5 2A1.5 1.5 0 002 3.5V5c0 1.149.15 2.263.43 3.326a13.022 13.022 0 009.244 9.244c1.063.28 2.177.43 3.326.43h1.5a1.5 1.5 0 001.5-1.5v-1.148a1.5 1.5 0 00-1.175-1.465l-3.223-.716a1.5 1.5 0 00-1.767 1.052l-.267.933c-.117.41-.555.643-.95.48a11.542 11.542 0 01-6.254-6.254c-.163-.395.07-.833.48-.95l.933-.267a1.5 1.5 0 001.052-1.767l-.716-3.223A1.5 1.5 0 004.648 2H3.5zM16.5 4.56l-3.22 3.22a.75.75 0 11-1.06-1.06l3.22-3.22h-2.69a.75.75 0 010-1.5h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V4.56z" />
                  </svg>
                </span>
                <h1 class="m-0 p-0 secondary-heading">
                  Contact Info
                </h1>
                <p class="m-0 p-0 lead text-white">
                  Provide contact information
                </p>
              </li>
              <li class="mb-5 ml-3 cursor-pointer">
                <span class="position-absolute d-flex align-items-center justify-content-center rounded-full stepper-icon">
                  <svg aria-hidden="true" class="" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>
                </span>
                <h4 class="m-0 p-0 secondary-heading">
                  Education Info
                </h4>
                <p class="m-0 p-0 text-white">
                  Provide information about your studies
                </p>
              </li>
              <li class="mb-5 ml-3 cursor-pointer">
                <span class="position-absolute d-flex align-items-center justify-content-center rounded-full stepper-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                    <path d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
                  </svg>
                </span>
                <h4 class="m-0 p-0 secondary-heading">
                  Success
                </h4>
                <p class="m-0 p-0 text-white">
                  You have successfully registered
                </p>
              </li>
            </ol>

          </div>
          <div>.</div>
          <div>.</div>
        </div>
      </div>
      <div class="col-md-8 h-100">
        <div class="h-100 flex-column d-flex align-items-center justify-content-center ">
          <div class="mx-auto pt-3 w-half">
            <div class="mt-5 mb-2">
              <h1 class="m-0 p-0">
                We will like to contact you
              </h1>
              <p>Kindly provide us with full details to contact you</p>
            </div>
            <b-overlay :show="loading">
              <validation-observer ref="registerGraduateForm" #default="{invalid}">
                <b-form @submit.prevent="onSubmit">
                  <b-form-group label-size="lg" label="Your full name (as indicated on your international passport )" label-for="full_name">
                    <validation-provider #default="{ errors }" name="full name" rules="required" vid="full name">
                      <div class="exxtra-input">
                        <span class="leading">
                          <PersonIcon />
                        </span>
                        <input id="full_name" v-model="registration.full_name" type="text" name="full name"
                               placeholder="Bruce wayne"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Your active email address" label-size="lg" label-for="email">
                    <validation-provider #default="{ errors }" name="Email" rules="required|email" vid="email">
                      <div class="exxtra-input">
                         <span class="leading">
                    <RegistrationMail />
                  </span>
                        <input id="login-username" v-model="registration.email" type="email" name="register-email"
                               placeholder="brucewayne@exxtra.app"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!--                Phone -->
                  <b-form-group label="Valid active phone Number" label-size="lg" label-for="phone">
                    <validation-provider #default="{ errors }" name="Phone" rules="required|length:10" vid="phone">
                      <div class="exxtra-input">
                  <span class="leading">
                    <RegistrationPhone />
                  </span>
                        <input id="phone" v-model="registration.phone" name="register-phone" placeholder="0201234567" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <div class="d-flex justify-content-between">
                    <b-button variant="outline-primary" @click="$router.push({name: 'gradloans'})">
                      Return
                    </b-button>
                    <b-button variant="primary" class="px-5" type="submit" :disabled="invalid">
                      Next
                    </b-button>
                  </div>

                </b-form>
              </validation-observer>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PersonIcon from "@/assets/svg/Person.svg";
import RegistrationMail from "@/assets/svg/RegistrationMail.svg";
import RegistrationPhone from "@/assets/svg/RegistrationPhone.svg";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { email, required } from '@validations';
import { get, has } from "lodash";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BButton, BForm, BFormGroup, BFormInput, BOverlay } from "bootstrap-vue";

export default {
  name: "GraduateContact",
  components: {
    BButton,
    PersonIcon,
    RegistrationMail,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    RegistrationPhone,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      registration: {
        email: '',
        phone: '',
        full_name: '',
      },
      loading: false,
      interval: 0,
      result: 0,
      // validation rules
      required,
      email
    }
  },
  mounted() {
    this.restoreGraduateApplicant();
  },
  methods: {
    async onSubmit() {
      try {
        const success = await this.$refs.registerGraduateForm.validate();
        if (!success) {
          return;
        }

        this.loading = true

        this.$router.push({ name: 'gradloans-info' }).catch(() => {})
        localStorage.setItem('graduate_applicant', JSON.stringify(this.registration))
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        // console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error_message,
          },
        });
      } finally {
        this.loading = false
      }
    },
    restoreGraduateApplicant() {
      const graduate_applicant = JSON.parse(localStorage.getItem('graduate_applicant'))
      if (!graduate_applicant) return;

      const completed_stage_two = has(graduate_applicant, ['undergraduate_country', 'post_graduate_programme', 'loan_amount'])

      const next_route = completed_stage_two ? "gradloans-registered" : "gradloans-info"
      this.$router.push({ name: next_route })
    },
    generateCaptcha() {
      this.captcha = Array.from({ length: 2 }, () => Math.floor(Math.random() * 40));
    }
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/base/pages/page-grad-applications.scss';
.captcha {
  font-size: 1.4rem !important
}
</style>
